import React from 'react';
import SEO from '../components/seo';
import { Container, H1, H2, P } from '../styles/styles';
import { GlobalStyle } from '../styles/global';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'gatsby';

const terms = () => {
  return (
    <React.Fragment>
      <SEO title="Terms of service" />
      <GlobalStyle />
      <Header />
      <Container style={{ maxWidth: '600px', marginBottom: '100px' }}>
        <div style={{ marginTop: '100px', marginBottom: '32px' }}>
          <H1>Terms of service</H1>
          <P style={{ marginTop: '12px' }}>Effective date: 6 Dec, 2020</P>
        </div>
        <P>
          Welcome to Design Lobby App. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at: <span>hello@designlobby.app</span>
        </P>
        <br />
        <P>
          By using our site, you confirm that you accept these terms of use and that you agree to comply with them, If you do not agree to these terms, you must not use our site. The following terms also apply to your use of our site, service and forum:Our <Link to="/privacy">Privacy Policy</Link>, which sets out how we may use your personal information.
        </P>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Changes.</H2>
        </div>
        <P>We reserve the right, at our sole discretion, to modify or replace these Terms at any time, By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</P>
        <br />
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Site availability</H2>
        </div>
        <P>We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</P>
        <br />
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Account</H2>
        </div>
        <P>When you create an account with us (the “Account”), you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</P>
        <br />
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>No representation or warranty</H2>
        </div>
        <P>
          You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. The Services may contain links or connections to websites or services of third parties that are not owned or controlled by Design Lobby. When you access third-party websites or use third-party
          services, you accept that there are risks in doing so, and that Linear is not responsible for such risks.
        </P>
        <br />
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <H2>Customer Support</H2>
        </div>
        <P>
          To find more information about the Service and its features, or if you need assistance with your Account, please use the in-app chat or send us an e-mail at <span>hello@designlobby.app</span> In the event of any conflict between these Terms of Service and information provided by customer support or other portions of our Site or Application, these Terms will control.
        </P>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default terms;
